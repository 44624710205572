import React from 'react';
import {Modal, Button} from 'antd';
import styles from "./TermsModal.module.scss"

type TermsModalProps = {
    isTermsModalVisible: boolean;
    setIsTermsModalVisible: (isTermsModalVisible: boolean) => void;
}

export const TermsModal: React.FC<TermsModalProps> = ({isTermsModalVisible, setIsTermsModalVisible}) => {
    return (
        <div className={styles.termsModalWrapper}>
            <Modal
                onCancel={() => setIsTermsModalVisible(false)}
                visible={isTermsModalVisible}
                footer={[
                    <Button key="cancel" onClick={() => setIsTermsModalVisible(false)}>
                        Cancel
                    </Button>
                ]}
            >
                <div className={styles.section}>
                    <h1>Acceptance of the Terms of Use</h1>
                    <p>By accessing or using the <span className={styles.website}>Blink Website</span>, or by clicking
                        to accept or agree to the Terms of Use when this option is made available to you, you represent
                        that you have read and understood, and agree to be bound by these Terms of Use. If you do not
                        agree to these Terms of Use, you may not use the Blink Website.</p>
                    <p>Personally identifiable information collected about you by this Blink Website is treated in
                        accordance with Cognyte Software <a
                            href="https://www.cognyte.com/privacy-policy/?source=SocialOrg?source=SocialOrg"
                            target="_blank">Privacy Policy</a>, which is hereby incorporated into these Terms of Use by
                        reference.
                    </p>
                </div>
                <div className={styles.section}>
                    <h1>Access and usage</h1>
                    <p>
                        We reserve the right not to perform any or all of the Blink services with respect to any content
                        uploaded by you to the Blink Website, to terminate or modify the Blink Website in whole or in
                        part, in any manner in our sole discretion, without notice. We will not be liable if, for any
                        reason, all or any part of the Blink Website is unavailable at any time or for any period. From
                        time to time, we may restrict access to all or part of the Blink Website to a subset of
                        permitted users. You are solely responsible for making all arrangements necessary for you to
                        access the Blink Website.
                    </p>
                    <p>
                        You shall use the Blink Website exclusively to register and evaluate the Blink services and/or
                        products, and you shall access it only through the registration portal, regardless of any
                        previous registration or access. To access the Blink Website or certain resources, you may be
                        asked to provide certain registration details or other information. It is a condition of your
                        use of the Blink Website, and you hereby represent and warrant, that all information you provide
                        is correct, current and complete. If you provide your contact information to us, you agree that
                        we may contact you with information regarding our services, including but not limited to
                        newsletters, advertisements and other marketing materials. You must logout from the Blink
                        Website at the end of each session. You agree that you are solely responsible to us for all
                        activities identified with your access and registration. Cognyte has the absolute right to
                        disable any user name or password, at any time, for any reason, including, if in our sole
                        discretion we believe that you have failed to comply with any provision of these Terms of Use.
                    </p>
                    <p>
                        You consent to the disclosure of the data uploaded by you to the Blink Website to Cognyte, and
                        provide Cognyte with irrevocable consent to use such data as Cognyte deems fit. You represent
                        and warrant that you have all rights and consents necessary from third parties, if any, to
                        upload such data and provide Cognyte with such rights as aforesaid with respect thereto. In
                        addition, you are solely responsible for securing any privacy-related rights and permissions
                        from relevant third parties, as may be required under applicable law or compliance obligations.
                    </p>
                </div>
                <div className={styles.section}>
                    <h1>Prohibited Uses</h1>
                    <ul>
                        <p>You agree not to access or use (and shall not permit others to access or use) the Blink
                            Website or Cognyte intellectual property in any manner:</p>
                        <li>Other than for evaluation purposes.</li>
                        <li>To process, transmit or introduce any confidential or sensitive information of any kind,
                            including personally identifiable information, sensitive personal data, health information
                            of any kind and the like.
                        </li>
                        <li>To license, sub license, sell, re sell, rent, lease, transfer, distribute or time share or
                            otherwise make them available for access by third parties.
                        </li>
                        <li>To disassemble, reverse engineer, decompile or modify them or otherwise create derivative
                            works of them.
                        </li>
                        <li>To access them for the purpose of developing a product or service that competes with a
                            Cognyte Product.
                        </li>
                        <li>To use them to create, use, send, store or run viruses or other harmful computer code,
                            files, scripts, agents or other programs or engage in any other malicious act.
                        </li>
                        <li>To disrupt their security, integrity or operation.</li>
                        <li>To remove or modify a copyright or other proprietary rights notice in them.</li>
                        <li>To use them to reproduce, distribute, display, transmit or use material protected by
                            copyright or other intellectual property right (including the rights of publicity or
                            privacy) without first obtaining the permission of the owner.
                        </li>
                        <li>To use them to damage the property of another.</li>
                        <li>That violates any applicable federal, state, local or international law or regulation, or
                            advocates, promotes or assists in any unlawful act, constitutes an illegal threat or
                            violates export control laws.
                        </li>
                    </ul>
                </div>
                <div className={styles.section}>
                    <h1>Intellectual Property</h1>
                    <p>All rights, title and interest in and to all the intellectual property rights in the Blink
                        services and/or products are owned exclusively by Cognyte and its licensors, notwithstanding any
                        other provision in these Terms of Use or any other agreement. Such intellectual property may be
                        protected by United States and/or international laws regarding copyrights, trademarks, trade
                        secrets and other intellectual property or proprietary rights. No right, title or interest in or
                        to the Blink services and/or products is transferred to you. Cognyte reserves all rights not
                        expressly granted to you herein. You hereby grant to Cognyte a royalty free, fully paid, non
                        exclusive, non transferable, sub licensable, worldwide right to use electronic data uploaded or
                        processed by you to the Blink Website. You shall not copy, modify, create derivative works of,
                        publicly display or perform, republish, download or store, or transmit any Cognyte intellectual
                        property without Cognyte’s express prior written consent or except as expressly provided in
                        these Terms of Use.</p>
                </div>
                <div className={styles.section}>
                    <h1>Additional Considerations</h1>
                    <div className={styles.subSection}>
                        <h3>Disclaimer of Warranties</h3>
                        <p>YOUR USE OF THE BLINK WEBSITE, INCLUDING ANY WEBSITE CONTENT, AND ANY SERVICES OR ITEMS
                            OBTAINED
                            THROUGH THE BLINK WEBSITE, IS AT YOUR OWN RISK AND PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                            BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER COGNYTE NOR
                            ANY
                            PERSON ASSOCIATED WITH COGNYTE MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE BLINK WEBSITE.
                            WITHOUT LIMITING THE FOREGOING, NEITHER COGNYTE NOR ANYONE ASSOCIATED WITH COGNYTE
                            REPRESENTS OR
                            WARRANTS THAT THE BLINK WEBSITE WILL BE ACCURATE, RELIABLE, ERROR FREE OR UNINTERRUPTED,
                            THAT
                            DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                            VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE BLINK WEBSITE OR ANY SERVICES OR ITEMS
                            OBTAINED
                            THROUGH THE BLINK WEBSITE WILL INDEED BE PROVIDED AND/OR OTHERWISE MEET YOUR NEEDS OR
                            EXPECTATIONS.
                            COGNYTE HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR
                            OTHERWISE, WITH RESPECT TO THE BLINK WEBSITE AND THE WEBSITE CONTENT, INCLUDING BUT NOT
                            LIMITED
                            TO ANY WARRANTIES OF MERCHANTABILITY, NON INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
                            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                            APPLICABLE LAW.
                        </p>
                    </div>

                    <div className={styles.subSection}>
                        <h3>Limitation of Liability</h3>
                        <p>EXCEPT TO THE EXTENT THAT A DISCLAIMER OF LIABILITY IS PROHIBITED UNDER APPLICABLE LAW, IN NO
                            EVENT WILL COGNYTE, ITS AFFILIATES AND ITS LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                            OFFICERS AND DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
                            OUT OF
                            OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE BLINK WEBSITE, INCLUDING ANY
                            WEBSITE
                            CONTENT, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
                            DAMAGES,
                            INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS
                            OF
                            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                            GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                            CONTRACT OR
                            OTHERWISE, EVEN IF FORESEEABLE.</p>
                    </div>

                    <div className={styles.subSection}>
                        <h3>Indemnification</h3>
                        You agree to defend, indemnify and hold Cognyte, its affiliates and licensors and their
                        respective
                        officers, directors, employees, contractors, agents, licensors and suppliers harmless from and
                        against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
                        (including reasonable attorneys' fees and other costs of defense) resulting from your violation
                        of
                        these Terms of Use or your use of the Blink Website, including, without limitation, any use of
                        the
                        Blink Website's content, services and products other than as expressly authorized in these Terms
                        of
                        Use or your use of any information obtained from the Blink Website.
                    </div>

                    <div className={styles.subSection}>
                        <h3>Waiver and Severability</h3>
                        <p>No waiver of these Terms of Use by Cognyte shall be deemed a further or continuing waiver of
                            such term or condition or any other term or condition, and any failure of Cognyte to assert
                            a right or provision under these Terms of Use shall not constitute a waiver of such right or
                            provision.
                            If any provision of these Terms of Use is held by a court of competent jurisdiction to be
                            invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
                            limited to the minimum extent such that the remaining provisions of the Terms of Use will
                            continue in full force and effect.
                        </p>
                    </div>

                    <div className={styles.subSection}>
                        <h3>Entire Agreement</h3>
                        <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you
                            and Cognyte with respect to the Blink Website and supersede all prior and contemporaneous
                            understandings, agreements, representations and warranties, both written and oral, with
                            respect to the Blink Website. For the avoidance of doubt, these Terms of Use do not
                            supersede any ordering agreement, subscription agreement or other agreement pursuant to
                            which you have been granted the right to use or access a Cognyte product or service, if and
                            as applicable.</p>
                    </div>


                </div>
            </Modal>
        </div>
    );
};