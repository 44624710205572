import React, {useCallback, useState} from 'react';
import {Field, Form, Formik, FormikValues} from "formik";
import styles from "./MainForm.module.scss"
import {
    COMMENTS_FIELD_PLACEHOLDER,
    COMPANY_FIELD_PLACEHOLDER,
    EMAIL_FIELD_PLACEHOLDER,
    NAME_FIELD_PLACEHOLDER,
    PHONE_FIELD_PLACEHOLDER,
    ROLE_FIELD_PLACEHOLDER
} from "../Template/Template";
import {NotificationError} from "../../utils/Notifications";
import {Modal} from 'antd';
import {FileUpload} from "../FileUpload/FileUpload";
import {uploadToFirebase} from "../../services/UploadService";
import {Checkbox, Button} from 'antd';
import {TermsModal} from "../TermsModal/TermsModal"
import {Loader} from "../Loader/Loader"

function validateEmail(email: string) {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
}

const checkIsFormValid = (values: FormikValues) => {
    if (!values.email) {
        NotificationError('Error', 'Email is required')
    } else if (!validateEmail(values.email)) {
        NotificationError('Error', 'Wrong email format')
    }

    let isValid = 0
    Object.entries(values).forEach((value, index) => {
        if (value[0] === 'email' && validateEmail(value[1])) {
            isValid = 1
        }
    })

    return isValid
}

export const MainForm = ({setIsLoading}: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);

    const [formValues, setFormValues] = useState({});
    const [files, setFiles] = useState({
        uploadedFiles: [] as File[]
    });
    const [resetForm, setResetForm] = useState(null)
    const [isAgree, setIsAgree] = useState(false)


    const submitAction = (values: FormikValues, resetForm: () => void) => {
        if (files.uploadedFiles.length > 0) {
            uploadToFirebase({values, files}, resetForm, setFiles, setIsLoading)
        } else {
            setIsModalVisible(false)
            uploadToFirebase({values}, resetForm, setFiles, setIsLoading)
        }
    }

    const submit = (values: FormikValues, {resetForm}: any) => {
        const isValid = checkIsFormValid(values)

        if (isValid) {
            setResetForm(() => resetForm)
            if (files.uploadedFiles.length === 0) {
                setFormValues(values)
                setIsModalVisible(true)
            } else {
                submitAction(values, resetForm)
            }
        }
    }


    const onDrop = (acceptedFiles: File[]) => {
        setFiles({
            ...files,
            uploadedFiles: [
                ...files.uploadedFiles,
                ...acceptedFiles
            ]
        })
    }

    return <>
        <Modal visible={isModalVisible} onOk={() => submitAction(formValues, resetForm as any)}
               onCancel={() => setIsModalVisible(false)}>
            <p>Are you sure to submit without uploading files?</p>
        </Modal>
        <TermsModal isTermsModalVisible={isTermsModalVisible} setIsTermsModalVisible={setIsTermsModalVisible}/>

        <FileUpload files={files} onDrop={onDrop} setFiles={setFiles}/>

        <div className={styles.mainFormWrapper}>
            <Formik
                initialValues={{name: "", company: "", email: "", phone: "", role: "", comments: ""}}
                onSubmit={submit}
            >
                {({errors, touched}) => (
                    <Form>
                        <div className={styles.fileInputWrapper}>
                        </div>

                        <div className={styles.infoFields}>
                            <div className={styles.infoFieldItems}>
                                <Field name="email" type="text" placeholder={EMAIL_FIELD_PLACEHOLDER}/>
                                <span className={styles.required}>*</span>
                                <Field name="name" type="text" placeholder={NAME_FIELD_PLACEHOLDER}/>
                                <Field name="company" type="text" placeholder={COMPANY_FIELD_PLACEHOLDER}/>
                                <Field name="phone" type="tel" placeholder={PHONE_FIELD_PLACEHOLDER}/>
                                <Field name="role" type="text" placeholder={ROLE_FIELD_PLACEHOLDER}/>
                                <Field name="comments" type="text" placeholder={COMMENTS_FIELD_PLACEHOLDER}/>
                            </div>

                            <div className={styles.formControls}>
                                <div className={styles.termsAgreement}>
                                    <Checkbox onChange={() => setIsAgree(!isAgree)}>I agree to the </Checkbox>
                                    <span className={styles.terms}
                                          onClick={() => setIsTermsModalVisible(true)}>terms</span>
                                </div>
                                <div className={styles.submitBtnWrapper}>
                                    <Button type="primary" htmlType="submit" disabled={!isAgree}>Send</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </>
};