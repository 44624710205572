import React, {useState} from 'react';
import './App.css';
import 'antd/dist/antd.css';
import {Template} from "./components/Template/Template";
import {MainForm} from "./components/MainForm/MainForm";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {Loader} from "./components/Loader/Loader"

function App() {

    const [isLoading, setIsLoading] = useState(false)

    return <>
        <ReactNotification/>
        <Loader isLoading={isLoading}/>
        <Template>
            <MainForm setIsLoading={setIsLoading}/>
        </Template>
    </>
}

export default App;
