import React, {ReactElement} from 'react';
import styles from "./Template.module.scss"
import logo from "../../assets/images/logo.svg"
import fingerprint from "../../assets/images/fingerprint.png"
import cognyteLogo from "../../assets/images/cognyte-logo.svg"

type TemplateProps = {
    children: ReactElement<any, any>
}


const MAIN_HEADER = 'Welcome to the BLINK file submission platform'
const DESCRIPTION = 'Please submit your file(s) and your details so we can get back to you'
export const NAME_FIELD_PLACEHOLDER = 'Your name'
export const COMPANY_FIELD_PLACEHOLDER = 'Your organization'
export const EMAIL_FIELD_PLACEHOLDER = 'Your email'
export const PHONE_FIELD_PLACEHOLDER = 'Your phone'
export const ROLE_FIELD_PLACEHOLDER = 'Your role'
export const COMMENTS_FIELD_PLACEHOLDER = 'Your comments'

export const Template: React.FC<TemplateProps> = ({children}) => {
    return (
        <div className={styles.templateWrapper}>

            <div className={styles.content}>
                <div className={styles.rightSection}>
                    <div className={styles.logoWrapper}>
                        <img src={logo} alt="logo"/>
                    </div>
                    <h1 className={styles.mainHeader}>{MAIN_HEADER}</h1>
                    <p className={styles.description}>{DESCRIPTION}</p>
                    <div className={styles.children}>
                        {children}
                    </div>
                </div>

                <div className={styles.leftSection}>
                    <div className={styles.fingerprintWrapper}>
                        <img src={fingerprint} alt="fingerprint-image"/>
                    </div>
                </div>
            </div>

            <footer className={styles.footer}>
                <div className={styles.footerMainText}>
                    <p>© 2021 All Rights Reserved</p>
                </div>
                <div className={styles.footerLogo}>
                    <img src={cognyteLogo} alt="footer-logo"/>
                </div>
            </footer>
        </div>
    );
};