import React from 'react';
import {useDropzone} from "react-dropzone";
import "./FileUpload.scss"
import {CloseCircleOutlined, FileFilled} from "@ant-design/icons";

type FileUploadProps = {
    onDrop: any,
    files: {uploadedFiles: File[]},
    setFiles: any
}

export const FileUpload: React.FC<FileUploadProps> = ({onDrop, files, setFiles}) => {

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop
    });

    return <div {...getRootProps()}>
        <input className="dropzone-input" {...getInputProps()} />
        <div className="text-center">
            <div className={"uploadedFiles"}>
                {files.uploadedFiles.length > 0 && Array.from(files.uploadedFiles).map((file: any, index) => {
                    return <div className={"uploadedFile"}><FileFilled/>{file.name}<span onClick={(e) => {
                        setFiles({
                            ...files,
                            uploadedFiles: files.uploadedFiles.filter((f: File) => (f.name !== file.name))
                        })

                        e.stopPropagation()
                        e.preventDefault()
                    }}><CloseCircleOutlined/></span>
                    </div>
                })}
            </div>
            <div className="content">
                {isDragActive ? (
                    <p className="dropzone-content">Drop the file(s) here</p>
                ) : (
                    <p className="dropzone-content">
                        <div className={'chooseFilesBtn'}>Choose file(s)</div>
                        <p>Or drop file(s) here</p>
                    </p>
                )}
            </div>
        </div>
    </div>
};