import {storage} from "../config";
import {NotificationSuccess, NotificationError} from "../utils/Notifications";

export const uploadToFirebase = (data: any, resetForm: () => void, setFiles: any, setIsLoading: (isLoading: boolean) => void) => {
    setIsLoading(true)

    const storageRef = storage.ref();

    const dataRef = storageRef.child(`europol/${data.values.email}/data_${(new Date().toJSON().slice(0, 19))}.json`);
    const message = JSON.stringify(data.values);

    dataRef.putString(message).then((snapshot) => {
        if (data.files && data.files.uploadedFiles.length > 0) {
            Array.from(data.files.uploadedFiles).forEach((file: any, index) => {
                const fileRef = storageRef.child(`europol/${data.values.email}/${file.name}`);

                fileRef.put(file)
                    .then(() => {
                        if (index === data.files.uploadedFiles.length - 1) {
                            NotificationSuccess('Success', `File and form submitted successfully`)
                            setFiles({
                                ...data.files,
                                uploadedFiles: []
                            })
                            resetForm()
                        }
                    }).catch((e: any) => {
                    NotificationError('Error', 'Error uploading files')
                    }).finally(() => {
                        setIsLoading(false)
                    })
            })
        } else {
            NotificationSuccess('Success', 'Form was submitted successfully')
            resetForm()
            setIsLoading(false)
        }
    }).catch((e: any) => {
        NotificationError('Error', 'Error uploading files')
    })
};